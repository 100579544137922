
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import "./styles.css";
import services from "./images/services.png";

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Head = makeShortcode("Head");
const Steps = makeShortcode("Steps");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Head mdxType="Head">
	<title>How to implement authentication cheaply and securely</title>
	<meta name="twitter:card" content="summary_large_image" />
	<meta name="twitter:site" content="@smakosh" />
	<meta name="twitter:title" content="How to implement authentication cheaply and securely" />
	<meta name="twitter:description" content="How to implement authentication cheaply and securely" />
	<meta name="twitter:image" content="https://2021.custom-auth.talks.smakosh.com/images/card.png" />
    </Head>
    <h1>{`Hello world!`}</h1>
    <hr></hr>
    <h2>{`Implementing authentication cheaply and securely`}</h2>
    <hr></hr>
    <h2>{`About me`}</h2>
    <ul>
      <li parentName="ul">{`I'm Ismail Ghallou, a Software Consultant @ `}<a parentName="li" {...{
          "href": "https://thelanding.app?utm_source=smakosh_talk"
        }}>{`The Landing`}</a>
        <blockquote parentName="li">
          <p parentName="blockquote">{`A social design platform that empowers everyone to create together`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`Building `}<a parentName="li" {...{
          "href": "https://ontwik-dev.com?utm_source=smakosh_talk"
        }}>{`Ontwik`}</a>{` with my friend Jeffrey!`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`A hiring platform that connects remote developers with founders around the world`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`I love open source, building digital products and watching anime.`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`My fav project is a portfolio theme that you can deploy in one click and Code geass is my fav anime`}</p>
        </blockquote>
      </li>
    </ul>
    <hr></hr>
    <p>{`Key points`}</p>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Custom auth vs services`}</li>
        <li parentName="ul">{`Sessions with cookies vs jwt with localStorage`}</li>
        <li parentName="ul">{`Pros/Cons of jwt`}</li>
        <li parentName="ul">{`Pros/Cons of http only cookie`}</li>
        <li parentName="ul">{`Demo`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`Let's get started!`}</h2>
    <hr></hr>
    <h2>{`Custom auth VS services`}</h2>
    <hr></hr>
    <h2>{`Custom implementation`}</h2>
    <h3>{`Pros:`}</h3>
    <ul>
      <li parentName="ul">{`Cheap`}</li>
      <li parentName="ul">{`Secure (relatively) if you use trusted and maintained libraries`}</li>
      <li parentName="ul">{`Control over data`}</li>
    </ul>
    <hr></hr>
    <h3>{`Cons:`}</h3>
    <ul>
      <li parentName="ul">{`You're responsible if something breaks`}</li>
      <li parentName="ul">{`Costs time and engineering resources to implement`}</li>
    </ul>
    <hr></hr>

    <div style={{
      width: 900,
      height: 900
    }}>
	<img src={services} />
    </div>
    <hr></hr>
    <h2>{`Services`}</h2>
    <h3>{`Pros`}</h3>
    <ul>
      <li parentName="ul">{`Time saving and doesn't require lot of engineering resources to implement`}</li>
      <li parentName="ul">{`Support provided by the service`}</li>
      <li parentName="ul">{`The service is responsible if something breaks`}</li>
      <li parentName="ul">{`Scalable automatically`}</li>
      <li parentName="ul">{`Secure (relatively) and the service is responsible if something goes wrong`}</li>
    </ul>
    <hr></hr>
    <h3>{`Cons`}</h3>
    <ul>
      <li parentName="ul">{`It gets expensive`}</li>
      <li parentName="ul">{`You don't have full control over your data`}</li>
      <li parentName="ul">{`Locked to the service`}</li>
    </ul>
    <hr></hr>
    <h2>{`Sessions with cookies VS JWT with localStorage`}</h2>
    <hr></hr>
    <h2>{`Sessions with cookies`}</h2>
    <h3>{`Pros`}</h3>
    <ul>
      <li parentName="ul">{`Secure, not vulnerable to XSS and CSRF attacks (if you set it to be http only and define the sameSite property with `}<inlineCode parentName="li">{`Lax`}</inlineCode>{` as value)`}</li>
      <li parentName="ul">{`Cookie can be passed through sub domains`}</li>
    </ul>
    <hr></hr>
    <h3>{`Cons`}</h3>
    <ul>
      <li parentName="ul">{`Cookie cannot be passed cross domains`}</li>
      <li parentName="ul">{`Requires server side validation each time the user visits a protected route`}</li>
    </ul>
    <hr></hr>
    <h2>{`JWT with localStorage`}</h2>
    <h3>{`Pros`}</h3>
    <ul>
      <li parentName="ul">{`You can set the same token on different applications/domains`}</li>
      <li parentName="ul">{`Doesn't require server side validation each time the user visits a protected route`}</li>
    </ul>
    <hr></hr>
    <h3>{`Cons`}</h3>
    <ul>
      <li parentName="ul">{`vulnerable to XSS attacks`}</li>
    </ul>
    <hr></hr>
    <h2>{`Time to code!`}</h2>
    <hr></hr>
    <h2>{`Questions time!`}</h2>
    <hr></hr>
    <p>{`Refs:`}</p>
    <ul>
      <li parentName="ul">{`GraphQL Docs`}</li>
      <li parentName="ul">{`GraphQL Codegen Docs`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://redislabs.com/solutions/use-cases/session-management/"
        }}>{`https://redislabs.com/solutions/use-cases/session-management/`}</a></li>
      <li parentName="ul">{`Next js Docs`}</li>
      <li parentName="ul">{`MDX Deck and Code surf for slides`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Cross-site_request_forgery"
        }}>{`https://en.wikipedia.org/wiki/Cross-site_request_forgery`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Cross-site_scripting"
        }}>{`https://en.wikipedia.org/wiki/Cross-site_scripting`}</a></li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;